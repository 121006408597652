  /* ///////// Package ////////// */
  .package-card{
    background: linear-gradient(146deg, rgb(255 180 38) 0%, rgb(222 134 59) 100%);
    height: 130px;
    background: '#222';
    border-radius: 15px;
  }
  .shadow-out{
    box-shadow: inset 2px 2px 4px 0 hsla(0,0%,100%,.7), 3px 3px 4px 0 rgba(0,0,0,.19);
  }
  .dialog-text p{
    margin: 0;
  }
  .converted-text p{
    margin: 0;
  }
  .button-theme{
    background: #222;
    background-color: #222;
    background: linear-gradient(99deg, rgb(255 38 38) 0%, rgb(222 59 59) 100%);
    border-radius: 48px;
    color:#fff!important;
  }
  .button-theme:hover{
    background: #febc42;
    background: linear-gradient(99deg, rgb(254, 57, 57) 0%, rgb(221, 55, 55) 100%);
  }
  .mobile-width-fixed{
    position: fixed;
  }
  .mobile-width-fixed, .mobile-width{
    width:420px!important;
    max-width:100%;
  }
  .MuiDialog-paperWidthSm{
    width:350px!important;
    max-width: 80%!important;
  }
  .MuiDialog-paperWidthXs-51{
    width:350px!important;
    max-width: 80%!important;
  }
  .productHover{
    border-radius: 15px!important;
    border:none!important;
    height: 100%; 
    position: relative;
    top: 0;
    transition: 0.5s;
  }
  .productHover:hover{
    box-shadow:0 0 19px 0 #00000029;
    top: -5px;
    transition: 0.5s;
  }
  .fixed-header-padding{
    padding-top: 70px;
  }
  .red-button-style{
    background:#e53935!important;
    color:#ffffff!important;
    cursor: pointer;
    border-radius: 48px!important;
    box-shadow:3px 2px 6px 0 #f4625f87, inset 1px 1px 3px 0 #ffffff8f!important; 
  }
  .starter-button-style{
    background: rgb(115,30,62);
    background: linear-gradient(56deg, rgba(115,30,62,1) 0%, rgba(238,42,47,1) 43%, rgba(245,129,78,1) 100%);
    box-shadow:3px 2px 6px 0 #f4625f87, inset 1px 1px 3px 0 #ffffff8f;
    cursor: pointer;
    border-radius: 20px;
    color:#fff;
    padding: 13px 28px;
    width: 80%;
    margin: -46px auto 0px;
    text-align: center;
  }
  
  #product_images img{
    max-width: 100%!important;
  }
  .MuiOutlinedInput-root{
    border-radius: 48px!important;
    box-shadow: 2px 2px 6px 0 #00000026
  }
  .wallet .awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) * 0.9);
  }
  .wallet .awssld__content{
    border-radius: 15px;
  }
  .home .awssld__wrapper{
    border-radius: 15px;
  }
  .home .awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) * 1)!important;
  }
  .partner .awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) * 0.59);
  }
  .partner .awssld__content{
    background-color: transparent;
    border-radius: 15px;
  }
  .partner .awssld__wrapper{
    border-radius: 15px;
  }
  .partnerbanner{
    object-fit: contain; 
    display: block;
    width: 100%;
    border-radius: 15px;
  }
  /* .awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) * 0.58);
  } */
  .awssld__content
  {
    background-color: #feb940;
  }
  .cnyFrameTop{
    background-image: url(/images/cny/frame-top.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .cnyFrameBottom{
    background-image: url(/images/cny/frame-bottom.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }

  .shop .awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) * 0.215);
  }

  .setting-title-bar{
    padding:5px 15px;
    border-radius: 48px;
    color:#fff
  }
  
  img{
    max-width: 100%!important;
  }

  .txt_truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .txt_truncate2{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 2.86em;
  }

  .FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #7795f8;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #829fff;
    border-radius: 4px;
  }
  
  .FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #819efc;
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  /* .orgchart{
    transform: scale(0.5);
  } */
  .orgchart-container{
    border:2px dashed #d9d9d9;
    margin: 10px 0 0;
  }
  /* .orgchart-container {
    display: flex;
    justify-content: center;
  } */
  .slick-prev {
    left:-11px!important
  }
  .slick-next {
    right:-15px !important;
  }
  .slick-prev, .slick-next {
    top:33%!important
  }
  .slick-prev:before, .slick-next:before{
    color: #c2c2c2!important;
  }

  .image-gallery-content.fullscreen{
    top: 20vh;
    width: 420px;
    max-width: 100%;
    margin: 0 auto;
  }

  .announcement-menu{
    max-height: 150px;
    position: relative;
    background-color: #fcfdff!important;
    box-shadow: 1px -4px 6px 0px #0000001c;
    border-radius: 0!important;
    border: none!important;
  }

  .item .transformHeader{
    animation-name: tored;
  animation-duration: 3s;
  transition-timing-function: ease-out;
  }

  .item .transformHeaderTransparent{
    animation-name: totransparent;
  animation-duration: 2s;
  transition-timing-function: ease-in;
  }

  @keyframes totransparent {
    0% {background-color: #e53935;}
    100%  {background-color: transparent;}
  }

  @keyframes tored {
    0%  {background-color: transparent;}
    100% {background-color: #e53935;}
  }

  @media only screen and (max-width: 600px){
    .shop img{
      height: 89px;
    }
    .shop .awssld__container{
      padding-bottom: calc(var(--slider-height-percentage) * 0.41);
    }
  }

  